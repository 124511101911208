import {Component} from "vue-property-decorator"
import {VNode} from "vue"

import {Language, Qualification} from "@/types";
import {appStore} from "@/store";
import TextInput from "@/components/form/TextInput";
import Button from "@/components/form/Button";
import {isEmpty} from "@/constants/DataBoundaries";
import {PaymentSettings, PersonalSettings, UserForm, UserValidation} from "@/_modules/profile/types";
import {Validations} from "vuelidate-property-decorators";
import {RecursivePartial} from "@/utils/typescript-library-extensions";
import {required} from "vuelidate/lib/validators";
import {ValidationFieldSet, ValidationObject, ValidationRuleSet} from "@/utils/vuelidate-extension";
import {
  getServiceTranslationPropertyName,
  getServiceTypesSorted,
  getServiceTypeTranslationByKey
} from "@/_modules/service/store/services";
import {ServiceType, ServiceTypeSubCategoriesWithQualification} from "@/_modules/service/types";
import SelectInput from "@/components/form/SelectInput";
import I18nOptions from "@/utils/I18nOptions";
import {adminStore, SetUserQualification, UserChangeStatusPayload} from "@/_modules/admin/store/admin-store";
import FormMixin from "@/mixins/FormMixin";
import CustomerProfileData from "@/_modules/admin/components/CustomerProfileData";
import CheckBox from "@/components/form/CheckBox";
import {ButtonType, TextInputType} from "@/constants/Elements"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as ResponseError from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants";

interface Form extends RecursivePartial<Customer> {
  adminName: string;
  customerEmail: string;
  selectedCategory: string;
  selectedQualification: Qualification;
  userVerify: boolean;
}

export interface Customer extends RecursivePartial<PersonalSettings> {
  profile: RecursivePartial<UserForm>;
  payment: Partial<PaymentSettings>;
  qualification: ServiceType[];
}

interface FormValidation extends ValidationObject<Form> {
  adminName: ValidationRuleSet;
  customerEmail: ValidationRuleSet;
  profile: RecursivePartial<UserValidation>;
  payment: ValidationFieldSet<PaymentSettings>;
}

interface ComponentValidation {
  form: FormValidation;
}


export class AdminPanelErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: ResponseError.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.UserNotFound:
        this.errorMessageKey = 'err_user_not_found'
        break
      default:
        await super.handleBackendError(e)
    }
  }
}
const adminPanelErrorHandler = new AdminPanelErrorHandler()

@Component({name: 'UserProfileManagement'})
export default class extends FormMixin {

  public renderConfirmBtn: boolean = false
  public categoryList: ServiceType[] = []

  public form: Form = {
    profile: undefined,
    payment: {
      iban: ''
    },
    accounts: [],
    qualification: [],
    adminName: '',
    customerEmail: '',
    selectedCategory: '',
    selectedQualification: Qualification.UNKNOWN,
    userVerify: false
  }

  @Validations()
  public validations(): ComponentValidation {
    return {
      form: {
        customerEmail: {required},
        adminName: {},
        profile: {},
        payment: {
          iban: {}
        },
        accounts: [],
        qualification: {},
        selectedCategory: {required},
        selectedQualification: {},
        userVerify: {}
      }
    }
  }

  public async mounted(): Promise<void> {
    await this.fetchData()
  }

  public async fetchData(): Promise<void> {
    if (!isEmpty(this.form.customerEmail)) {
      await this.onSearchClick()
    }
  }

  public get serviceTypeOptions(): ServiceType[] {
    return getServiceTypesSorted(appStore.locale, true)
  }

  private async onSearchClick(event?: Event): Promise<void> {
    if (event !== undefined) {
      event.preventDefault()
    }

    if (this.checkValidation(this.$v.form.customerEmail)) {
      const resp = await this.withRequest(adminStore.findUser(encodeURIComponent(this.form.customerEmail)), adminPanelErrorHandler)
      this.form.profile = {
        ...resp.profile,
        phoneNumber: resp.profile.phoneNumber !== undefined ? {
          countryCode: resp.profile.phoneNumber.countryCode,
          prefix: resp.profile.phoneNumber.prefix,
          number: resp.profile.phoneNumber.number
        } : undefined
      }
      this.form.qualification = resp.qualification
      this.form.payment = resp.payment
      this.buildCategoryList()
    }
  }

  private async setUserQualification(): Promise<void> {
    if (this.checkValidation(this.$v.form.selectedCategory)) {
      const payload: SetUserQualification = {
        userId: this.form.profile!.id!,
        category: this.form.selectedCategory,
        qualification: this.form.selectedQualification
      }
      await this.withRequest(adminStore.setUserQualification(payload), adminPanelErrorHandler)
      await this.fetchData()
    }
  }

  private async onChangeUserStatus(): Promise<void> {
    const userChangeStatusPayload: UserChangeStatusPayload = {
      userId: this.form.profile!.id!,
      verified: true
    }

    await this.withRequest(adminStore.changeUserStatus(userChangeStatusPayload), adminPanelErrorHandler)
    await this.fetchData()
  }

  public getServiceTypesSorted(locale: Language, serviceTypes: ServiceTypeSubCategoriesWithQualification[]): ServiceTypeSubCategoriesWithQualification[] {
    //const translationPropertyName = getServiceTranslationPropertyName(locale)
    //serviceTypes.sort(propertyStringComparator(translationPropertyName))
    return serviceTypes
  }

  public getServiceSubCatKey(key: string): string {
    const result = this.form!.qualification!.flatMap((it) => {
      return it!.subCategories!.filter((iter) => {
        return iter!.category === key
      })
    })
    return result[0]!.category!
  }

  private async onRemoveQualification(elem: ServiceType): Promise<void> {
    const payload: SetUserQualification = {
      userId: this.form.profile!.id!,
      category: elem.category,
      qualification: Qualification.UNKNOWN
    }
    await this.withRequest(adminStore.setUserQualification(payload), adminPanelErrorHandler)
    await this.fetchData()
  }

  public buildCategoryList(): void {
    this.categoryList = this.form.qualification!.map(it => it as ServiceType)
    /*const result = (this.form!.qualification!.flatMap((it) => {
      return it!.subCategories!.flatMap((iter) => {
        if (iter!.qualification !== Qualification.UNKNOWN) {
          return {
            category: iter!.category!,
            qualification: iter!.qualification
          }
        } else {
          return undefined
        }
      })
    })).filter((value) => {return value !== undefined})
    this.categoryList = lodash.cloneDeep(result) as CategoryList[]*/
  }

  public render(): VNode {
    return (
      <form novalidate onsubmit={(e: Event) => this.onSearchClick(e)}>
        <b-container>
          <b-row class="bg-light justify-content-center">
            <b-col class="mt-3" cols="8">
              <b-row>
                <b-col cols="9">
                  <TextInput
                    id="email"
                    type={TextInputType.Email}
                    placeholder={this.translation('adm.lbl_ph_search')}
                    v-model={this.form.customerEmail}
                    invalidMessage={this.buildInvalidMessage(this.$v.form.customerEmail!)}
                  />
                </b-col>
                <b-col align-v="end" cols="3">
                  <Button
                    type={ButtonType.Submit}
                    disabled={isEmpty(this.form.customerEmail)}
                    label={this.translation('btn_search')}
                    variant="primary"
                    v-model={this.form.customerEmail}
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          {!(isEmpty(this.form?.profile?.email) || isEmpty(this.form.customerEmail)) &&
          <div>
            <hr class="delimiter"/>
            <b-row class="bg-light pt-3">
              <b-col>
                <CustomerProfileData
                  value={this.form}
                  v={this.$v.form.profile!}
                />
                <b-row class="mt-5">
                  <b-col class="text-right">
                    <CheckBox
                      disabled={this.form.profile?.verified}
                      label={this.translation('adm.lbl_user_status_change')}
                      size="lg"
                      checked={this.form.profile?.verified}
                      onChange={() => {
                        this.renderConfirmBtn = !this.renderConfirmBtn
                      }}
                    />
                  </b-col>
                </b-row>
                {(this.renderConfirmBtn && !this.form.profile!.verified) &&
                <b-row class="pb-3">
                  <b-col class="text-right">
                    <Button
                      label={this.translation("btn_confirm")}
                      variant="primary"
                      onClick={() =>
                        this.onChangeUserStatus().then(() => {
                          this.renderConfirmBtn = false
                        })
                      }
                    />
                  </b-col>
                </b-row>
                }
              </b-col>
            </b-row>
            <hr class="delimiter"/>
            <h5>
              {this.translation('adm.title_category_list')}
            </h5>
            {this.categoryList.length === 0 &&
              <b-row>
                <b-col>
                  {this.translation('msg_table_empty')}
                </b-col>
              </b-row>
            }
            {this.categoryList.length > 0 &&
            <b-row>
              <b-col>
                <b-row class="bg-light p-3">
                  <b-col>
                    {this.categoryList.map((it) => {
                      if (it.qualification !== Qualification.UNKNOWN) {
                        return (
                          <b-row class="subcategory-slot my-2">
                            <b-col>
                              {getServiceTypeTranslationByKey(appStore.locale, it.category)}
                            </b-col>
                            <b-col>
                              <span>
                                {this.translation(`enum_qualification_grade_${it.qualification}`)}
                              </span>
                              <span class="float-right">
                                <b-link class="text-danger" style="border: 1px solid #e8274f; padding: 0 3px 0 3px; border-radius: 3px;" onClick={() => {
                                  const elem = it as ServiceType
                                  this.onRemoveQualification(elem)
                                }}>
                                  {this.translation('btn_delete')}
                                </b-link>
                              </span>
                            </b-col>
                          </b-row>
                        )} else {
                        return null
                      }
                    })}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            }
            {<hr class="delimiter"/>}
            <b-row class="bg-light p-3">
              <b-col>
                <b-row>
                  <b-col>
                    <SelectInput
                      label={this.translation('fld_service_category')}
                      options={this.serviceTypeOptions}
                      valueField="category"
                      //value={getRelativeTopLevelCategory(this.form.selectedCategory)}
                      v-model={this.form.selectedCategory}
                      invalidMessage={this.buildInvalidMessage(this.$v.form.selectedCategory!)}
                      /*onChange={() => {
                        this.form.selectedCategory = ''
                      }}*/
                      textField={getServiceTranslationPropertyName(appStore.locale)}
                    />
                  </b-col>
                  {/*<b-col cols="4">
                    <SelectInput
                      label={this.translation('lbl_sub_category')}
                      options={!isEmpty(this.topLevelCategory) ? getRelativeSubCategories(this.topLevelCategory, appStore.locale, true) : getAllSubCategories(appStore.locale, true)}
                      valueField="category"
                      textField={getServiceTranslationPropertyName(appStore.locale)}
                      v-model={this.form.selectedCategory}
                      onChange={() => {
                        this.topLevelCategory = getRelativeTopLevelCategory(this.form.selectedCategory)
                      }}
                      invalid-message={this.buildInvalidMessage(this.$v.form.selectedCategory!)}
                    />
                  </b-col>*/}
                  <b-col>
                    <SelectInput
                      label={this.translation('lbl_qualification')}
                      options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Qualification), 'enum_qualification_grade_', true)}
                      value={this.form.selectedQualification}
                      onInput={(v: Qualification) => {
                        this.form.selectedQualification = v
                      }}
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <Button
                      label={this.translation('btn_save')}
                      variant="primary"
                      onClick={() => {
                        this.setUserQualification()
                      }}
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>}
        </b-container>
      </form>
    )
  }
}
