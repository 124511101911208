import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import {TsxComponent} from "@/components/TsxComponent"
import TableComponent from "@/components/TableComponent"
import CheckBox from "@/components/form/CheckBox"
import Button from "@/components/form/Button"

import {FieldLike, TableRow} from "@/utils/vue-bootstrap-types"
import {AgreementInternal, PaidPayload} from "@/_modules/admin/store/admin-store"
import {BackendUrls} from "@/constants/APIconstants"
import moment from "moment"

interface Props {
  value: AgreementInternal[];
  tableType: 'confirmed' | 'paidToProvider' | 'confirmedAndPaidToProvider' | 'paidAndCompleted';
  onConfirmPaidToProvider?(payload: PaidPayload): void;
}

@Component({name: 'AgreementsTable'})
export default class extends TsxComponent<Props> {
  @Prop(Array) public readonly value!: AgreementInternal[]
  @Prop(String) public readonly tableType!: 'confirmed' | 'paidToProvider' | 'confirmedAndPaidToProvider' | 'paidAndCompleted'

  public get toBePaidTableFields(): FieldLike[] {
    const fields = [
      {
        label: this.translation('adm.lbl_panel_agreement_number'),
        key: 'agreementNumber'
      },
      {
        label: this.translation('adm.lbl_panel_agreement_dates'),
        key: 'agreement_dates'
      },
      {
        label: this.translation('adm.lbl_panel_agreement_amount_in_eur'),
        key: 'servicePrice'
      },
      {
        label: this.translation('adm.lbl_panel_agreement_name_and_surename'),
        key: 'providerName'
      },
      {
        label: this.translation('adm.lbl_panel_agreement_payment_date'),
        key: 'paid_to_provider_at'
      },
      {
        label: this.translation('adm.lbl_panel_agreement_bank_account'),
        key: 'bank_account'
      },
      {
        label: this.translation('adm.lbl_panel_confirm_payment'),
        key: 'btn_payment_confirm'
      }
    ]
    
    switch (this.tableType) {
      case 'confirmed':
        return fields.filter((it) => {
          return it.key !== 'paid_to_provider_at'
        })
      case 'paidToProvider':
        return fields.filter((it) => {
          return it.key !== 'btn_payment_confirm'
        })
      case 'paidAndCompleted':
        return fields.filter((it) => {
          return it.key !== 'btn_payment_confirm' && it.key !== 'bank_account' && it.key !== 'paid_to_provider_at'
        })
      case 'confirmedAndPaidToProvider':
        return fields.filter((it) => {
          return it.key !== 'btn_payment_confirm' && it.key !== 'bank_account' && it.key !== 'paid_to_provider_at'
        })
      default:
        throw new Error(`table type ${this.tableType} not implemented, check 'Agreements' component.`)
    }
  }
  
  private confirmPaidToProvider(obj: AgreementInternal): void {
    const payload: PaidPayload = {
      agreementType: obj.agreementType,
      agreementId: obj.agreementId
    }
    this.$emit('confirmPaidToProvider', payload)
  }
  
  public render(): VNode {
    return (
      <div>
        <b-row class="bg-light">
          <b-col class="pt-3">
            <b-row>
              <b-col>
                <TableComponent
                  auto
                  responsive="sm"
                  borderless
                  hover={false}
                  striped={false}
                  items={this.value}
                  fields={this.toBePaidTableFields}
                  scopedSlots={{
                    "cell(agreementNumber)": (obj: TableRow<AgreementInternal>) => {
                      return (
                        <div>
                          <b-link style="text-decoration: underline;" href={`${BackendUrls.urlApi}${BackendUrls.admFile}/${encodeURIComponent(obj.item.agreementFileId)}`}>
                            {obj.item.agreementNumber}
                          </b-link>
                        </div>
                      )
                    },
                    "cell(agreement_dates)": (obj: TableRow<AgreementInternal>) => {
                      return (
                        <div>
                          <span class="text-secondary">{this.translation('adm.lbl_agreements_deal_concluded')}{moment(obj.item.concludedAt).format('DD.MM.YYYY HH:mm')}</span><br/>
                          <span class="text-info">{this.translation('adm.lbl_agreements_payment_date')}{moment(obj.item.paidByRequesterAt).format('DD.MM.YYYY HH:mm')}</span><br/>
                          {obj.item.confirmedByRequesterAt && 
                            <span class="text-success">
                              {this.translation('adm.lbl_agreements_complete_date')}
                              {moment(obj.item.confirmedByRequesterAt).format('DD.MM.YYYY HH:mm')}
                            </span>
                          }
                          <br />
                        </div>
                      )
                    },
                    "cell(servicePrice)": (obj: TableRow<AgreementInternal>) => {
                      return (
                        <div>
                          {obj.item.servicePrice.amount} {obj.item.servicePrice.currency}
                        </div>
                      )
                    },
                    "cell(name)": (obj: TableRow<AgreementInternal>) => {
                      return (
                        <div>
                          {obj.item.providerName}
                        </div>
                      )
                    },
                    "cell(paid_to_provider_at)": (obj: TableRow<AgreementInternal>) => {
                      return (
                        <div>
                          {moment(obj.item.paidToProviderAt).format('DD.MM.YYYY HH:mm')}
                        </div>
                      )
                    },
                    "cell(bank_account)": (obj: TableRow<AgreementInternal>) => {
                      return (
                        <div>
                          {obj.item.providerIBAN}
                        </div>
                      )
                    },
                    "cell(btn_payment_confirm)": (obj: TableRow<AgreementInternal>) => {
                      if (obj.item.setCheck !== undefined) {
                        return (
                          <div>
                            <CheckBox
                              checked={obj.item.setPaid}
                              disabled={obj.item.setPaid}
                              onChange={(v: boolean) => {
                                obj.item.setCheck = v
                              }}
                            />
                            {obj.item.setCheck &&
                            <Button
                              label={this.translation('btn_confirm')}
                              size="sm"
                              variant="primary"
                              onClick={() => {
                                const item = obj.item
                                this.confirmPaidToProvider(item)
                              }}
                            />
                            }
                          </div>
                        )
                      } else {
                        return undefined
                      }
                    }
                  }}
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    )
  }
}