import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import FormMixin from "@/mixins/FormMixin";
import DatePickerInput from "@/components/form/DatePickerInput";
import Button from "@/components/form/Button";
import {ButtonType} from "@/constants/Elements";
import moment from "moment";
import TableComponent from "@/components/TableComponent";
import {FieldLike, TableRow} from "@/utils/vue-bootstrap-types";
import {adminStore, IncomeReport, ReportPayload} from "@/_modules/admin/store/admin-store";
import {serverDateFormat} from "@/constants/ApplicationConfiguration";

interface Form {
  startDate: Date;
  endDate: Date;
}

@Component({name: 'AccountingAndReports'})
export default class extends FormMixin {
  
  public form: Form = {
    startDate: moment().startOf('week').toDate(),
    endDate: moment().toDate()
  }
  
  public reports: IncomeReport[] = []
  
  // public testReports: IncomeReport[] = [
  //   {
  //     date: String(moment().format('DD-MM-YYYY')),
  //     dealsCount: '3',
  //     incomeAmount: {
  //       amount: '35',
  //       currency: Currency.EUR
  //     },
  //     taxAmount: {
  //       amount: '33.90',
  //       currency: Currency.EUR
  //     },
  //     totalAmount: {
  //       amount: '22.10',
  //       currency: Currency.EUR
  //     }
  //   }
  // ]
  
  public get tableFields(): FieldLike[] {
    return [
      {
        label: this.translation('adm.lbl_table_reports_date'),
        key: 'date'
      },
      {
        label: this.translation('adm.lbl_table_reports_deals_count'),
        key: 'deals_count'
      },
      {
        label: this.translation('adm.lbl_table_reports_income_amount'),
        key: 'income'
      },
      {
        label: this.translation('adm.lbl_table_reports_tax_amount'),
        key: 'tax'
      },
      {
        label: this.translation('adm.lbl_table_reports_total_amount'),
        key: 'total'
      }
    ]
  }
  
  public mounted(): void {
    this.fetchReports()
  }
  
  public async fetchReports(): Promise<void> {
    const payload: ReportPayload = {
      startDate: moment(this.form.startDate).format(serverDateFormat),
      endDate: moment(this.form.endDate).format(serverDateFormat)
    }
    this.reports = await this.withRequest(adminStore.getIncomeReport(payload))
  }
  
  public async onSubmit(e: Event): Promise<void> {
    e.preventDefault()
    await this.fetchReports()
  }
  
  public render(): VNode {
    return (
      <form novalidate onsubmit={(e: Event) => this.onSubmit(e)}>
        <b-container>
          <b-row class="bg-light">
            <b-col class="py-3">
              <b-row>
                <b-col>
                  <DatePickerInput
                    label={this.translation('adm.lbl_agreements_date_from')}
                    v-model={this.form.startDate}
                  />
                </b-col>
                <b-col>
                  <DatePickerInput
                    label={this.translation('adm.lbl_agreements_date_to')}
                    v-model={this.form.endDate}
                  />
                </b-col>
              </b-row>
              <b-row class="text-right">
                <b-col>
                  <Button
                    type={ButtonType.Submit}
                    label={this.translation('btn_search')}
                    variant="primary"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <hr/>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <TableComponent
                    auto
                    responsive="md"
                    borderless
                    hover={false}
                    striped={false}
                    emptyKey="msg_table_empty"
                    items={this.reports}
                    fields={this.tableFields}
                    scopedSlots={{
                      "cell(date)": (obj: TableRow<IncomeReport>) => {
                        return (
                          <div>
                            {obj.item.date}
                          </div>
                        )
                      },
                      "cell(deals_count)": (obj: TableRow<IncomeReport>) => {
                        return (
                          <div>
                            {obj.item.dealsCount}
                          </div>
                        )
                      },
                      "cell(income)": (obj: TableRow<IncomeReport>) => {
                        return (
                          <div>
                            {obj.item.incomeAmount.amount} {obj.item.incomeAmount.currency}
                          </div>
                        )
                      },
                      "cell(tax)": (obj: TableRow<IncomeReport>) => {
                        return (
                          <div>
                            {obj.item.taxAmount.amount} {obj.item.taxAmount.currency}
                          </div>
                        )
                      },
                      "cell(total)": (obj: TableRow<IncomeReport>) => {
                        return (
                          <div>
                            {obj.item.totalAmount.amount} {obj.item.totalAmount.currency}
                          </div>
                        )
                      }
                    }}
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </form>
    )
  }
}