import {Action, getModule, Module, VuexModule} from "vuex-module-decorators"

import axios from "@/api"
import HttpStatuses from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {UnexpectedServerResponseError} from "@/utils/errors"
import rootStore from "@/store"
import {FiltersMap, Price, Qualification} from "@/types"
import {Customer} from "@/_modules/admin/components/UserProfileManagement"
import queryString, {StringifiableRecord} from "query-string"

export interface ReportPayload {
  startDate: string;
  endDate: string;
}

export interface IncomeReport {
  date: string;
  dealsCount: string;
  incomeAmount: Price;
  taxAmount: Price;
  totalAmount: Price;
}

export interface SetUserQualification {
  userId: string;
  category: string;
  qualification: Qualification;
}

export interface UserChangeStatusPayload {
  userId: string;
  verified: boolean;
}

export interface AgreementInternal extends Agreement {
  setCheck?: boolean;
  setPaid?: boolean;
}

export enum AgreementType {
  REQUEST = "REQUEST",
  PROPOSAL = "PROPOSAL"
}

export interface Agreement {
  agreementId: string;
  agreementType: AgreementType;
  agreementFileId: string;
  agreementNumber: string;
  concludedAt: string;
  paidByRequesterAt: string;
  confirmedByRequesterAt?: string;
  servicePrice: Price;
  providerName: string;
  providerIBAN: string;
  paidToProviderAt: string;
}

export interface PaidPayload {
  agreementId: string;
  agreementType: AgreementType;
}

export enum AgreementCondition {
  CONFIRMED = "CONFIRMED",
  PAID_TO_PROVIDER = "PAID_TO_PROVIDER",
  PAID = "PAID",
  COMPLETED = "COMPLETED"
}

export enum AgreementsFilterFields {
  STATUS = "status",
  STARTDATE = "startDate",
  ENDDATE = "endDate"
}

function toAgreementsQuery(criteria: FiltersMap<AgreementsFilterFields>): StringifiableRecord {
  if (criteria !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyResult: any = {}
    for (const [key, value] of criteria.entries()) {
      anyResult[key] = value
    }
    return anyResult
  } else {
    return {}
  }
}

@Module({dynamic: true, namespaced: true, name: "admin", store: rootStore})
export class AdminModule extends VuexModule {
  
  @Action({rawError: true})
  public async findUser(email: string): Promise<Customer> {
    const resp = await axios.get(`${BackendUrls.findUser}${email}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
  
  @Action({rawError: true})
  public async setUserQualification(payload: SetUserQualification): Promise<void> {
    const resp = await axios.put(`${BackendUrls.qualification}`, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.NO_CONTENT)
  }
  
  @Action({rawError: true})
  public async changeUserStatus(payload: UserChangeStatusPayload): Promise<void> {
    const resp = await axios.put(`${BackendUrls.userVerify}`, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.NO_CONTENT)
  }
  
  @Action({rawError: true})
  public async getAgreements(criteria: FiltersMap<AgreementsFilterFields>): Promise<AgreementInternal[]> {
    const resp = await axios.get<AgreementInternal[]>(`${BackendUrls.search}?${queryString.stringify(toAgreementsQuery(criteria))}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    resp.data.forEach((it) => {
      it.setCheck = false
      it.setPaid = false
    })
    return resp.data
  }
  
  @Action({rawError: true})
  public async setPaidForProvider(paidPayload: PaidPayload): Promise<void> {
    const resp = await axios.put(`${BackendUrls.paidToProvider}`, paidPayload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.NO_CONTENT)
  }
  
  @Action({rawError: true})
  public async getIncomeReport(reportPayload: ReportPayload): Promise<IncomeReport[]> {
    
    const resp = await axios.get(`${BackendUrls.incomeReport}?startDate=${reportPayload.startDate}&endDate=${reportPayload.endDate}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
}

export const adminStore = getModule(AdminModule, rootStore)