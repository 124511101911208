import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";

@Component({name: "AdmMsgCenter"})
export default class AdminMsgCenter extends TsxComponent<{}> {
  public render(): VNode {
    return (
      <div>adm msg center</div>
    )
  }
}