import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import {PersonalSettings} from "@/_modules/profile/types";
import moment from "moment";

@Component({name: 'CustomerProfileData'})
export default class extends I18nComponent {
  @Prop(Object) public readonly value!: PersonalSettings

  public render(): VNode {
    return (
      <b-row>
        <b-col>
          <b-row>
            <b-col class="text-right">
              {this.translation('adm.lbl_user_id')}
            </b-col>
            <b-col>
              {this.value.profile?.id}
            </b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col class="text-right font-weight-bold">
              {this.translation('adm.lbl_user_verification_status')}
            </b-col>
            <b-col class={[this.value.profile.verified ? 'text-success' : 'text-warning', 'nowrap']}>
              {this.translation(`msg_param_verified_${this.value.profile?.verified}`)}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right font-weight-bold">
              {this.translation('adm.lbl_first_name')}
            </b-col>
            <b-col class="font-weight-bold">
              {this.value.profile?.name?.first}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right font-weight-bold">
              {this.translation('adm.lbl_last_name')}
            </b-col>
            <b-col class="font-weight-bold">
              {this.value.profile?.name?.last}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('adm.lbl_user_email')}
            </b-col>
            <b-col>
              {this.value.profile?.email}
            </b-col>
          </b-row>
          {this.value.profile.phoneNumber !== undefined &&
            <b-row>
              <b-col class="text-right">
                {this.translation('adm.lbl_user_phone_number')}
              </b-col>
              <b-col>
                +{this.value.profile.phoneNumber.prefix} {this.value.profile.phoneNumber.number}
              </b-col>
            </b-row>
          }
          <b-row>
            <b-col class="text-right">
              {this.translation('adm.lbl_user_sex')}
            </b-col>
            <b-col>
              {this.value.profile?.sex}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('adm.lbl_user_birth_date')}
            </b-col>
            <b-col>
              {this.value.profile?.birthDate !== undefined ? moment(this.value.profile?.birthDate).locale('et').format('DD-MM-YYYY') : undefined}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('lbl_country')}
            </b-col>
            <b-col>
              {this.value.profile.location?.address?.country}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('lbl_zip_code')}
            </b-col>
            <b-col>
              {this.value.profile.location?.address?.zipCode}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('lbl_city_or_county')}
            </b-col>
            <b-col>
              {this.value.profile.location?.address?.cityOrCounty}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('lbl_street')}
            </b-col>
            <b-col>
              {this.value.profile.location?.address?.address}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('adm.lbl_user_notification_language')}
            </b-col>
            <b-col>
              {this.translation(`fld_profile_settings_language_${this.value.profile.notificationLanguage?.toLowerCase()}`)}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              {this.translation('adm.lbl_user_iban')}
            </b-col>
            <b-col>
              {this.value.payment?.iban}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    )
  }
}
