import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import FormMixin from "@/mixins/FormMixin"

import DatePickerInput from "@/components/form/DatePickerInput";
import Button from "@/components/form/Button"

import {buildFilters, Name} from "@/types";
import {ButtonType} from "@/constants/Elements";
import {serverDateFormat} from "@/constants/ApplicationConfiguration";

import moment from "moment";

import AgreementsTable from "@/_modules/admin/components/AgreementsTable";
import {adminStore, AgreementCondition, AgreementsFilterFields, AgreementInternal, PaidPayload} from "@/_modules/admin/store/admin-store";

interface AgreementDates {
  dealConcludedDate: string;
  paymentDate: string;
  completeDate: string;
}

interface Item {
  agreement_id: string;
  agreement_dates: AgreementDates;
  amount_in_EUR: string;
  name: Name;
  bank_account: string;
  payed: boolean;
}

export interface ItemForm extends Item {
  checked: boolean;
}

interface Form {
  dateFrom: Date;
  dateTo: Date;
}

@Component({name: 'Agreements'})
export default class extends FormMixin {
  
  public progress: number = 0

  public form: Form = {
    dateFrom: moment().startOf('week').toDate(),
    dateTo: moment().toDate()
  }
  
  public confirmed: AgreementInternal [] = []
  public paidToProvider: AgreementInternal [] = []
  public confirmedAndPaidToProvider: AgreementInternal [] = []
  public paidAndCompleted: AgreementInternal [] = []

  public renderConfirmed: boolean = false
  public renderPaidToProvider: boolean = false
  public renderConfirmedAndPaidToProvider: boolean = false
  public renderPaidAndCompleted: boolean = false
  
  //public renderConfirmBtn: boolean = false
  
  /* eslint-disable */
  public async fetchAgreements(): Promise<void> {
    const confirmedFilter = buildFilters<AgreementsFilterFields>([
      [AgreementsFilterFields.STATUS, AgreementCondition.CONFIRMED]
    ])
    this.confirmed = await this.withRequest(adminStore.getAgreements(confirmedFilter))
    this.progress = await this.progress + 25

    const paidToProviderFilter = buildFilters<AgreementsFilterFields>([
      [AgreementsFilterFields.STATUS, AgreementCondition.PAID_TO_PROVIDER],
      [AgreementsFilterFields.STARTDATE, moment(this.form.dateFrom).format(serverDateFormat)],
      [AgreementsFilterFields.ENDDATE, moment(this.form.dateTo).format(serverDateFormat)]
    ])
    this.paidToProvider = await this.withRequest(adminStore.getAgreements(paidToProviderFilter))
    this.progress = await this.progress + 25

    const confirmedAndPaidToProviderFilter = buildFilters<AgreementsFilterFields>([
      [AgreementsFilterFields.STATUS, [AgreementCondition.CONFIRMED, AgreementCondition.PAID_TO_PROVIDER]],
      [AgreementsFilterFields.STARTDATE, moment(this.form.dateFrom).format(serverDateFormat)],
      [AgreementsFilterFields.ENDDATE, moment(this.form.dateTo).format(serverDateFormat)]
    ])
    this.confirmedAndPaidToProvider = await this.withRequest(adminStore.getAgreements(confirmedAndPaidToProviderFilter))
    this.progress = await this.progress + 25

    const paidAndCompletedFilter = buildFilters<AgreementsFilterFields>([
      [AgreementsFilterFields.STATUS, [AgreementCondition.COMPLETED, AgreementCondition.PAID]],
      [AgreementsFilterFields.STARTDATE, moment(this.form.dateFrom).format(serverDateFormat)],
      [AgreementsFilterFields.ENDDATE, moment(this.form.dateTo).format(serverDateFormat)]
    ])
    this.paidAndCompleted = await this.withRequest(adminStore.getAgreements(paidAndCompletedFilter))
    this.progress = await this.progress + 25
  }
  
  /* eslint-enable */
  
  public async mounted(): Promise<void> {
    await this.fetchAgreements()
  }
  
  public onSubmit(e: Event): void {
    e.preventDefault()
    this.fetchAgreements()
  }
  
  public async confirmPaidToProvider(payload: PaidPayload): Promise<void> {
    await this.withRequest(adminStore.setPaidForProvider(payload))
    await this.fetchAgreements()
  }
  
  public render(): VNode {
    return (
      <form novalidate onsubmit={(e: Event) => this.onSubmit(e)}>
        <b-container>
          {this.busy &&
            <b-progress value={this.progress} max="100" variant="success" animated />
          }
          {!this.busy && 
          <b-row class="bg-light">
            <b-col class="py-3">
              <b-row>
                <b-col>
                  <h6>
                    {this.translation('adm.title_agreements_to_be_paid')}
                    <b-link onClick={() => {
                      this.renderConfirmed = !this.renderConfirmed
                    }} class="font-weight-bold">
                      {this.confirmed.length}
                    </b-link>
                  </h6>
                </b-col>
              </b-row>
              <transition name="fade">
                {this.renderConfirmed &&
                <AgreementsTable
                  value={this.confirmed}
                  onConfirmPaidToProvider={(payload: PaidPayload) => this.confirmPaidToProvider(payload)}
                  tableType={'confirmed'}
                />
                }
              </transition>
              {!this.renderConfirmed && <hr/>}
              <b-row>
                <b-col>
                  <h6>{this.translation('adm.title_search_agreements')}</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <DatePickerInput
                    label={this.translation('adm.lbl_agreements_date_from')}
                    v-model={this.form.dateFrom}
                  />
                </b-col>
                <b-col>
                  <DatePickerInput
                    label={this.translation('adm.lbl_agreements_date_to')}
                    v-model={this.form.dateTo}
                  />
                </b-col>
              </b-row>
              <b-row class="text-right">
                <b-col>
                  <Button
                    type={ButtonType.Submit}
                    label={this.translation('btn_search')}
                    variant="primary"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h6>
                    {this.translation('adm.title_agreements_already_paid')}
                    <b-link onClick={() => {
                      this.renderPaidToProvider = !this.renderPaidToProvider
                    }}>
                      {this.paidToProvider.length}
                    </b-link>
                  </h6>
                </b-col>
              </b-row>
              <transition name="fade">
                {this.renderPaidToProvider &&
                <AgreementsTable
                  value={this.paidToProvider}
                  tableType={'paidToProvider'}
                />
                }
              </transition>
              <b-row>
                <b-col>
                  <h6>
                    {this.translation('adm.title_agreements_finished_payments')}
                    <b-link onClick={() => {
                      this.renderConfirmedAndPaidToProvider = !this.renderConfirmedAndPaidToProvider
                    }}>
                      {this.confirmedAndPaidToProvider.length}
                    </b-link>
                  </h6>
                </b-col>
              </b-row>
              <transition name="fade">
                {this.renderConfirmedAndPaidToProvider &&
                <AgreementsTable
                  value={this.confirmedAndPaidToProvider}
                  tableType={'confirmedAndPaidToProvider'}
                />
                }
              </transition>
              <b-row>
                <b-col>
                  <h6>
                    {this.translation('adm.title_agreements_unfinished_payments')}
                    <b-link onClick={() => {
                      this.renderPaidAndCompleted = !this.renderPaidAndCompleted
                    }}>
                      {this.paidAndCompleted.length}
                    </b-link>
                  </h6>
                </b-col>
              </b-row>
              <transition name="fade">
                {this.renderPaidAndCompleted &&
                <AgreementsTable
                  value={this.paidAndCompleted}
                  tableType={'paidAndCompleted'}
                />
                }
              </transition>
            </b-col>
          </b-row>}
        </b-container>
      </form>
    )
  }
}
